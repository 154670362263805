<template>
  <div class="card">
    <div class="card-header border-0 pt-3">
      <div class="card-title">
        {{ $t("menuPages.vaccination.vaccinationInfos") }}
        <!--
         <div
              v-if="originalVaccinationList.length != 0"
              class="d-flex align-items-right position-relative my-1"
            >
              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <inline-svg src="/media/icons/duotune/general/gen021.svg" />
              </span>
              <input
                type="text"
                v-model="search"
                @input="searchItems()"
                class="
                  form-control form-control-solid form-control-sm
                  w-180px
                  ps-10
                "
                style="margin-left: 15px"
                :placeholder="$t('menuPages.vaccineList.search')"
                              />
            </div>
            -->
      </div>
      <!--begin::Actions-->
      <div
        v-if="originalVaccinationList.length != 0"
        class="d-flex align-items-center py-1"
      >
        <div class="d-flex align-items-right position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="/media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="searchItems()"
            class="
              form-control form-control-solid form-control-sm
              w-180px
              ps-10
            "
            style="margin-left: 15px"
            :placeholder="$t('menuPages.vaccineList.search')"
          />
        </div>
      </div>
      <!--end::Actions-->
      <!--
      <div v-if="vaccinationList.length != 0" class="d-flex align-items-center py-1">
    
        <a
          href="#"
          class="btn btn-sm btn-primary"
          id="kt_toolbar_primary_button"
          data-bs-toggle="modal"
          data-bs-target="#create_vaccine_modal"
        >
          {{ $t("menuPages.vaccination.createVaccination") }}
        </a>
       
      </div>
       -->
    </div>
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div
          v-if="isLoading"
          style="
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-35px"
              src="/media/loading_icon.gif"
              style="width: 40px"
            />
          </div>
        </div>
        <div class="table-responsive" v-else-if="vaccinationList.length != 0">
          <table
            class="
              table
              align-middle
              table-row-dashed
              fs-6
              gy-5
              dataTable
              no-footer
            "
            id="kt_customers_table"
            role="grid"
          >
            <!--begin::Table-->
            <thead>
              <tr
                class="
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
                role="row"
              >
                <td>#</td>
                <td>{{ $t("menuPages.vaccination.vaccine") }}</td>
                <td>{{ $t("menuPages.vaccination.description") }}</td>
                <!--  <td>{{ $t("common.table.process") }}</td>-->
              </tr>
            </thead>

            <tbody class="fw-bold text-gray-600">
              <tr
                class="odd"
                v-for="(vaccination, index) in vaccinationList"
                :key="vaccination.vaccine.id"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ vaccination.vaccine.name }}</td>
                <td>{{ vaccination.vaccine.description }}</td>
                <!-- 
                <td>
                  <a
                    href="#"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                  </a>

                  <a
                    href="#"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="/media/icons/duotune/general/gen027.svg"
                      />
                    </span>
                  </a>
                </td>
                -->
              </tr>
            </tbody>
            <!--end::Table-->
          </table>
        </div>
        <div v-else class="card-px text-center">
          <div class="text-center px-5 pb-7">
            <img
              src="/media/animal/animal.jpg"
              class="symbol symbol-100px symbol-lg-160px mw-100 mh-150px"
            />
          </div>

          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold">
            <span>{{ $t("menuPages.vaccination.zeroCount") }}</span>
          </p>
          <!--end::Description-->
        </div>
      </div>

      <!--begin::Pagination-->
      <div
        class="d-flex flex-stack flex-wrap pt-10"
        v-if="vaccinationList.length != 0"
      >
        <!--begin::Controls-->
        <div class="d-flex flex-wrap my-1">
          <!--begin::Select wrapper-->
          <div class="m-0">
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-select-white form-select-sm
                fw-bolder
                w-125px
              "
              v-model="pageSize"
              @change="vaccineList(1)"
            >
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            <span>{{ $t("common.table.pagination.desc2") }}</span>
            <!-- Sayfa başı öğe sayısı -->

            <!--end::Select-->
          </div>
          <!--end::Select wrapper-->
        </div>
        <!--end::Controls-->

        <div class="fs-6 fw-bold text-gray-700">
          {{ totalItems }} {{ $t("common.table.pagination.desc") }}
          <!-- sonuç arasından -->
          {{ (currentPage - 1) * pageSize + 1 }} -
          {{ Math.min(currentPage * pageSize, totalItems) }}
          {{ $t("common.table.pagination.desc1") }}
          <!-- sonuç gösteriliyor. -->
        </div>

        <!--begin::Pages-->
        <ul class="pagination">
          <li
            class="page-item previous"
            @click="vaccineList(currentPage - 1)"
            :class="{ disabled: currentPage === 1 }"
          >
            <a href="#" class="page-link"><i class="previous"></i></a>
          </li>

          <li
            v-for="page in pageCount"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a href="#" class="page-link" @click="vaccineList(page)">{{
              page
            }}</a>
          </li>

          <li
            class="page-item next"
            @click="vaccineList(currentPage + 1)"
            :class="{ disabled: currentPage === pageCount }"
          >
            <a href="#" class="page-link"><i class="next"></i></a>
          </li>
        </ul>
        <!--end::Pages-->
      </div>
      <!--end::Pagination-->
    </div>
  </div>
  <CreateVaccine></CreateVaccine>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
// import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { VaccinationListModel } from "@/domain/vaccination/vaccinationList/model/VaccinationListModel";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_VACCINATION,
  components: {
    CreateVaccine,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const vaccinationList = ref<VaccinationListModel[]>([]);
    const originalVaccinationList = ref<VaccinationListModel[]>([]);
    const isLoading = ref<boolean>(false);

    const swalNotification = new SwalNotification();

    const formData = ref({
      vaccine: "D1",
    });

    watch(
      () => store.getters.newVaccine,
      () => {
        if (store.getters.newVaccine) vaccineList(1);
      }
    );

    const vaccinationController =
      store.state.ControllersModule.vaccinationController;
    if (!vaccinationController) {
      throw new Error("VaccinationController is not available in Vuex store.");
    }

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const vaccineList = async (page) => {
      isLoading.value = true;

      vaccinationList.value = [];
      originalVaccinationList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const vaccinationListModel: VaccinationListModel = {
        vaccine: {},
        page: page,
        pageSize: pageSize.value,
      };

      vaccinationController
        .vaccinationList(vaccinationListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((vaccination) => {
              totalItems.value = vaccination.pagination.total;
              currentPage.value = vaccination.pagination.page;
              pageCount.value = vaccination.pagination.pageCount;
              vaccinationList.value.push(vaccination);
              originalVaccinationList.value.push(vaccination);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };
    const search = ref<string>("");

    const searchItems = () => {
      if (search.value == "") {
        vaccinationList.value = [...originalVaccinationList.value];
      } else {
        vaccinationList.value = originalVaccinationList.value.filter(
          (vaccine) => searchVaccineByName(vaccine, search.value)
        );
      }
    };

    const searchVaccineByName = (vaccine, searchValue): boolean => {
      const vaccineName = vaccine.vaccine.name.toLowerCase();
      const searchLowerCase = searchValue.toLowerCase();

      return vaccineName.indexOf(searchLowerCase) !== -1;
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.VACCINATION));
      vaccineList(1);
    });

    return {
      currentPage,
      pageCount,
      vaccineList,
      vaccinationList,
      originalVaccinationList,
      formData,
      pageSize,
      totalItems,
      search,
      searchItems,
      isLoading,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
