
import { defineComponent, onMounted, ref, watch } from "vue";
// import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { VaccinationListModel } from "@/domain/vaccination/vaccinationList/model/VaccinationListModel";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_VACCINATION,
  components: {
    CreateVaccine,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const vaccinationList = ref<VaccinationListModel[]>([]);
    const originalVaccinationList = ref<VaccinationListModel[]>([]);
    const isLoading = ref<boolean>(false);

    const swalNotification = new SwalNotification();

    const formData = ref({
      vaccine: "D1",
    });

    watch(
      () => store.getters.newVaccine,
      () => {
        if (store.getters.newVaccine) vaccineList(1);
      }
    );

    const vaccinationController =
      store.state.ControllersModule.vaccinationController;
    if (!vaccinationController) {
      throw new Error("VaccinationController is not available in Vuex store.");
    }

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const vaccineList = async (page) => {
      isLoading.value = true;

      vaccinationList.value = [];
      originalVaccinationList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const vaccinationListModel: VaccinationListModel = {
        vaccine: {},
        page: page,
        pageSize: pageSize.value,
      };

      vaccinationController
        .vaccinationList(vaccinationListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((vaccination) => {
              totalItems.value = vaccination.pagination.total;
              currentPage.value = vaccination.pagination.page;
              pageCount.value = vaccination.pagination.pageCount;
              vaccinationList.value.push(vaccination);
              originalVaccinationList.value.push(vaccination);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };
    const search = ref<string>("");

    const searchItems = () => {
      if (search.value == "") {
        vaccinationList.value = [...originalVaccinationList.value];
      } else {
        vaccinationList.value = originalVaccinationList.value.filter(
          (vaccine) => searchVaccineByName(vaccine, search.value)
        );
      }
    };

    const searchVaccineByName = (vaccine, searchValue): boolean => {
      const vaccineName = vaccine.vaccine.name.toLowerCase();
      const searchLowerCase = searchValue.toLowerCase();

      return vaccineName.indexOf(searchLowerCase) !== -1;
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.VACCINATION));
      vaccineList(1);
    });

    return {
      currentPage,
      pageCount,
      vaccineList,
      vaccinationList,
      originalVaccinationList,
      formData,
      pageSize,
      totalItems,
      search,
      searchItems,
      isLoading,
    };
  },
});
